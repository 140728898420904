import { makeStyles } from "@mui/styles";
import { primaryColor } from "../scss/colors.module.scss";

export const useTabStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    boxShadow: "none",
    height: "100%",
    alignSelf: "stretch",
    backgroundColor: "transparent",
  },
  tabsFlexContainer: {
    height: "100%",
  },
  indicator: {
    backgroundColor: primaryColor,
  },
  tabs: {
    height: "100%",
  },
  tab: {
    color: "rgba(0, 0, 0, 0.54)",
    textTransform: "none",
    [theme.breakpoints.down("md")]: {
      "&$tab": {
        height: "100%",
      },
    },
  },
  selectedTab: {
    color: "#000000",
  },
}));
